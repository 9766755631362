
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import InboxPreview from '@/components/pages/tickets/InboxPreview.vue'

export default defineComponent({
  components: {
    TmModal,
    InboxPreview,
  },
})
