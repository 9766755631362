import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee9addf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shade--bg pa-5 border-radius-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inbox_preview = _resolveComponent("inbox-preview")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "ticketSettingsSender",
    "modal-title": "Sender name preview",
    "exact-size": "784px",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_inbox_preview, { name: "Textmagic Support" })
      ])
    ]),
    _: 1
  }))
}